import React from 'react';

import { ReactComponent as Logo } from '../../../assets/images/LogoEng_H_Green2.svg'
import {InstagramIcon, xIcon, linkedInIcon } from '../../../assets/icons/SocialIcons';
import SocialButtonAlt from '../SocialButtonAlt';

// TODO add callback for language change from App.js and add param for which language is selected
export default function Navbar() {

  
  
  return (
    
      <div className="container-fluid" style={{ marginTop: "3%", maxHeight: "100px", backgroundColor: "#19B092"}}>
        <div className="row align-items-center">
            <div className="col">
                <div className="text-start">
                    <p style={{ fontFamily: "Lora", color: "white", fontSize: "40px", fontWeight: "bold"}}>Doctori</p>
                </div>
            </div>

            <div className="col text-center">
                <p style={{ fontFamily: "Lora", color: "white", fontSize: "16px"}}>info@doctori.qa</p>
            </div>

            <div className="col d-none d-lg-flex justify-content-end" style= {{ height: "40px"}}>
                <SocialButtonAlt svgIcon={InstagramIcon} link={"https://instagram.com/doctori.qa"} />
                <SocialButtonAlt svgIcon={xIcon} link={"https://x.com/doctori_qa"} />
                <SocialButtonAlt svgIcon={linkedInIcon} link={"https://linkedin.com/company/doctori-qa"} />

            </div>

        </div>
        

        

        
      </div>
  );
};
