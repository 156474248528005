import React, { useState } from 'react';
import { ReactComponent as NewsletterBG } from '../../assets/images/Newsletter.svg';
import styles from './NewsletterSection.module.css'


const NewsletterSection = () => {

  const [email, setEmail] = useState('');
  const [subscribe, setSubscribe] = useState(true);

    const handleSubmit = async (event) => {
      
      event.preventDefault();

      if (isValidEmail(email)) {
        setEmail('')
        alert("Thanks for signing up to our news letter!");
      } else {
        alert("Please enter a valid email address")
        return;
      }

        const functionUrl = 'https://contactandmarketing.azurewebsites.net/api/newsletter_sub';
        const requestData = {
          type: 'newsletter', // Specify the type for the Azure Function to process correctly
          email: email,
          subscribe: true
      };
      console.log("test")
        const response = await fetch(functionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
          body: JSON.stringify(requestData)
        });

        if (response.ok) {
            console.log('Email submitted successfully');
            // Handle successful email submission (e.g., display a thank you message)
        } else {
            console.error('Error submitting email');
            // Handle errors (e.g., display an error message)
        }
    };

    function isValidEmail(email) {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
  }
  return (
    <div id="news-letter-section" className={`container-fluid ${styles.newsletterBackground}`} style={{ marginTop: "5%"}}>
      <div className={`container ${styles.newsletterContainer} text-center d-flex flex-column justify-content-center align-items-center`} style={{ height: "400px"}}>
        <h2 className={styles.newsletterTitle}>Join Our Newsletter</h2>
        <p className={styles.newsletterDescription}>
          Sign up to be the first to hear about our app's release — your new gateway to hassle-free healthcare. Plus, get the latest news and updates delivered straight to your inbox.
        </p>
        <form onSubmit={handleSubmit} className={`input-group mb-3 d-flex justify-content-center align-items-center ${styles.emailDiv}`}>
            <input type="text" id="emailInput" className={`form-control ${styles.emailField}`} placeholder="Email Address" aria-label="Email Address" aria-describedby="button-addon2" value={email} onChange={(e) => setEmail(e.target.value)}/>
            <button className={`${styles.newsLetterSubscribe}`} type="submit" id="button-addon2">Subscribe</button>
        </form>
        {/* <small className={styles.newsletterPrivacy}>
          We respect your privacy. <a style={{ color: "white"}}>Unsubscribe</a> at any time.
        </small> */} 
      </div>
    </div>
  );
};

export default NewsletterSection;