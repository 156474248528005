import React, { useState } from 'react';

import { ReactComponent as Logo } from '../../../assets/images/LogoEng_H_Green2.svg'
import {InstagramIcon, xIcon, linkedInIcon } from '../../../assets/icons/SocialIcons';
import SocialButton from '../SocialButton';
import styles from './Navrbar.module.css'
import {ReactComponent as HamburgerIcon } from '../../../assets/icons/hamburgerMenu.svg'

// TODO add callback for language change from App.js and add param for which language is selected
export default function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  
  
  return (
    <nav className="navbar navbar-light" >
      <div className="container-fluid">
        <a href="#" >
          <Logo style={{width: '200px', height: '70px'}}/>
        </a>

        <div className="d-lg-flex justify-content-end">

          {/* <button className={`${styles.hamburger}`} onClick={handleNavCollapse}>
            <HamburgerIcon />
          </button> */}

            

            <div className={`${styles.navbarCollapse} ${isNavCollapsed ? styles.collapsed : ''}`}>
              
              <SocialButton svgIcon={InstagramIcon} link={"https://instagram.com/doctori.qa"}/>
              <SocialButton svgIcon={xIcon} link={"https://x.com/doctori_qa"} />
              <SocialButton svgIcon={linkedInIcon} link={"https://linkedin.com/company/doctori-qa"} />
              {/* <a href="/client-portal" style={{ marginLeft: "10px"}}>
                <button className={`${styles.clientPortalButton}`}>
                  Client Portal
                </button>
              </a> */}
            </div>
          </div>

          {/* Mobile menu */}
          {/* <div className={`${styles.mobileMenu} ${isNavCollapsed ? styles.mobileMenuOpen : ''}`}> */}
              {/* <div className="row row-cols-1" style={{marginLeft: "10%", marginRight: "10%"}}>
                <div className="col">
                  <div className="btn" style={{ border: "2px solid black", width: "100%"}}>
                    <SocialButton svgIcon={InstagramIcon} link={"https://instagram.com/doctori.qa"} />  
                    <p>Follow us on Instagram</p>
                  </div>
                  
                </div>
                <div className="col">
                  <div className="btn d-flex" style={{ border: "2px solid black", width: "100%"}}>
                    <SocialButton svgIcon={xIcon} link={"https://x.com/doctori_qa"} />
                    <p>Follow us on Twitter</p>
                  </div>
                  
                </div>
                <div className="col">
                  <div className="d-flex justify-content-center text-center" style={{ border: "2px solid black"}}>
                    <SocialButton svgIcon={linkedInIcon} link={"https://linkedin.com/doctori.qa"} />
                    <p className="">Connect with us on LinkedIn</p>
                  </div>
                  
                </div>
                <div className="d-flex col justify-content-center" style={{ marginTop: "2%"}}>
                  <button href="#" className={styles.clientPortalButton} onClick={handleNavCollapse}>
                    Close
                  </button>
                </div> */}
                
                {/* <div className="d-flex col justify-content-center" style={{ marginTop: "2%"}}>
                  <button href="#" className={` ${styles.clientPortalButton} text-center`} onClick={handleNavCollapse}>
                    Close
                  </button>
              </div> */}
              
                
                
                
            {/* </div> */}
      </div>
    </nav>
  );
};