import React from 'react';
import styles from './HeroSection.module.css'
import { ReactComponent as HeroGroup } from '../../assets/images/GroupHero.svg';

const scrollToNewsLetter = () => {
    document.getElementById('news-letter-section').scrollIntoView({ 
        behavior: 'smooth' 
      });
};

export default function HeroSection() {

    return (
        <div className="container-fluid" style={{ height: "auto", backgroundColor: "#E8F7F4" }}>
            <div className="row">
                <div className="col" style={{marginLeft: "5%", marginTop: "5%"}}>
                    <p className={styles.herotext}>Discover Doctors Nearby and <span style={{ color: "#16A387"}}>
                        Schedule Appointments </span>
                    </p>
                        
                    <p className={styles.herodesc}>
                        Easily find and connect with doctors nearby.
                        Access quality healthcare at your fingertips.
                        Book appointments seamlessly.
                        Choose a date and time that suits you, and leave the rest to us.
                    </p>
                    <a href="#">
                        <button className={`${styles.genericButton}`} onClick={() => scrollToNewsLetter()}>
                            Stay Informed About the App Release
                        </button>
                    </a>

                </div>

                <div className="col d-none d-md-flex" style={{marginTop: "5rem", width: "50vw"}}>
                    <HeroGroup className={styles.heroGroup}/>
                </div>
                
            </div>


        </div>
    );
}

  