import React from 'react';
import HeroSection from './components/HeroSection';
import BenefitsSection from './components/BenefitsSection';
import NewsletterSection from './components/NewsletterSection';
import MarketingSection from './components/MarketingSection';
import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <HeroSection/>
        <BenefitsSection/>
        <NewsletterSection/>
        {/* <MarketingSection/> */}
      </main>
      <Footer/>
    </div>
  );
}

export default App;