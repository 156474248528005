import React, { useState } from 'react';
import ContactModal from '../common/ContactModal'
import { ReactComponent as Family } from '../../assets/images/Family.svg';
import { ReactComponent as BookAsset } from '../../assets/images/Book.svg';
import { ReactComponent as SearchAsset } from '../../assets/images/Search.svg';
import { ReactComponent as ReviewAsset } from '../../assets/images/Review.svg';
import BBreak from '../../assets/images/BBreak.png';
import styles from './BenefitsSection.module.css';

const BenefitsSection = () => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className={`container-fluid ${styles.benefitsSection}`}>
            <div className="row text-center">
                <div className="col">
                    <h2 className={styles.benefitsHeading}>Benefits of Our App</h2>
                </div>
            </div>
            <div className="row text-center">
                <div className="col d-flex justify-content-center">
                    <p className={styles.benefitsText} style={{ marginLeft: "2%", marginRight: "2%", marginBottom: "none" }}>
                        Easily find and connect with doctors nearby with our easy to use and intuitive application!
                    </p>
                </div>
            </div>
            <div className={`row justify-content-center ${styles.bbreak}`}>
                <img src={BBreak} alt="Break" style={{ maxWidth: "211px", maxHeight: "11px"}}/>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6 text-center">
                    <SearchAsset/>

                    <h3 style={{fontFamily: "Lora"}}>Find Doctors and Clinics</h3>
                    <p className={ styles.benefitsSecondary }>
                        Search for doctors and clinics in your area. Easily filter by insurance, price, rating, and more.
                        </p>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                    <BookAsset />

                    <h3 style={{fontFamily: "Lora"}}>Book Appointments</h3>
                    <p className={ styles.benefitsSecondary }>
                        We offer a seamless booking experience. Book, change, and cancel appointments with just a few taps.
                        </p>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                    <ReviewAsset />
                    <h3 style={{fontFamily: "Lora"}}>Leave Reviews</h3>
                    <p className={ styles.benefitsSecondary }>
                        Had a good experience with a doctor or clinic? Let others know!
                    </p>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                    <Family />
                    <h3 style={{fontFamily: "Lora"}}>Add Dependents</h3>
                    <p className={ styles.benefitsSecondary }>Easily add your children to your account for a seamless appointment booking experience for the family.</p>
                </div>
            </div>
            {/* <div className={`row justify-content-center ${styles.bbreak}`}>
                <img src={BBreak} alt="Break" style={{ maxWidth: "211px", maxHeight: "11px"}}/>
            </div> */}
            {/* <hr className={styles.fullWidthLine} />

            <div className="row">
                <div className="col text-center">
                    <h1 style={{fontFamily: "Lora"}}>Are You Interested In Partnering Up Your Clinic With Doctori?</h1>
                </div>
                
            </div>
            <div className="row" style={{ marginTop: "2%" }}>
                <div className="col d-flex justify-content-center">
                    <a style={{ marginLeft: "2em", marginRight: "2em"}} onClick={handleOpenModal}>
                    <button className={`${styles.clientPortalButton}`}>
                        Contact Us
                    </button>
                    </a>
                </div>
                <ContactModal showModal={showModal} handleCloseModal={handleCloseModal} />
                
            </div> */}
            
            
        </div>
    );
};

export default BenefitsSection;

